import { deleteToken } from '../client';

const Nav = () => (
  <nav className="flex-center">
    <img alt="logo" src="https://demo.hibilet.com/assets/hibilet-nav.svg" />
    <details className="dropdown">
      <summary>⚙️</summary>
      <ul dir="rtl">
        <li><a onClick={() => deleteToken('')}>Çıkış</a></li>
      </ul>
    </details>
  </nav>
);

export default Nav;
