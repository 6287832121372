/* eslint-disable no-unused-vars */
import QrScanner from 'qr-scanner';
import { useEffect, useRef, useState } from 'react';

const QR_CONFIG = {
  onDecodeError: () => null,
  preferredCamera: 'environment',
  highlightScanRegion: true,
  highlightCodeOutline: true,
  maxScansPerSecond: 4,
};

const Reader = ({ onQr }) => {
  const [decoded, setDecoded] = useState(null);

  const decodedRef = useRef(null);
  const scannerRef = useRef(null);
  const videoRef = useRef(null);

  const onScan = (result) => {
    decodedRef.current = result?.data;
    onQr(result?.data);
  };

  useEffect(() => {
    if (videoRef?.current && !scannerRef.current) {
      scannerRef.current = new QrScanner(videoRef?.current, onScan, QR_CONFIG);
      scannerRef?.current?.start().then(() => null).catch(() => null);
    }
    return () => {
      if (!videoRef?.current) {
        scannerRef?.current?.stop();
      }
    };
  }, []);

  return (
    <section className="camera">
      <div>
        <video style={{ width: '100%' }} ref={videoRef} />
      </div>
    </section>
  );
};

export default Reader;
