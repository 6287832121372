const errors = {
  'wrong-sale': 'Yanlış etkinlik bileti',
};

const Info = ({ account, sale, error }) => ((account && sale)
  ? (
    <section className="info">
      <h2>{account?.name}</h2>
      <hr />
      <h4>
        {sale?.name}
        <br />
        biletlerini okuyabilirsiniz
      </h4>
      {error && (
        <>
          <hr />
          <h4 className="error">{errors[error]}</h4>
        </>
      )}
    </section>
  )
  : <h1 aria-busy />);
export default Info;
