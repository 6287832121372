/* eslint-disable no-unused-vars */
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { get, put } from '../client';

const states = {
  success: 'Bilet geçerli',
  read: 'Bilet okunmuş',
  canceled: 'Bilet geçersiz',
  refunded: 'Bilet iade edilmiş',
};

const genders = {
  male: 'Erkek',
  female: 'Kadın',
  other: 'Diğer',
};

const Reservation = ({ qr, onDismiss, onError }) => {
  const [reservation, setReservation] = useState(null);
  const [show, setShow] = useState(null);

  const Dismiss = () => {
    onDismiss(null);
    setShow(null);
  };

  const GetReservation = () => {
    onError(null);
    get(`/reservations/${qr}`)
      .then(({ data }) => setReservation(data))
      .catch(({ message }) => onError(message));
  };

  const UpdateReservation = () => {
    put(`/reservations/${qr}`, { status: 'read' })
      .then(() => Dismiss())
      .catch(({ message }) => onError(message));
  };

  useEffect(() => { if (reservation) setShow(true); }, [reservation]);
  useEffect(() => { if (qr) GetReservation(); }, [qr]);

  return (
    <>
      <span onClick={Dismiss} className={`overlay ${show ? 'show' : ''}`} />
      <div className={`reservation ${show ? 'show' : ''}`}>
        <article>
          <div className="grid">
            <div>
              <sub>Adı soyadı</sub>
              <h4>{reservation?.owner}</h4>
            </div>
            <hr />
            <div className="grid grid-2">
              <div>
                <sub>Bilet türü</sub>
                <h4>{reservation?.product}</h4>
              </div>
              <div>
                <sub>Koltuk seçimi</sub>
                <h4>{reservation?.seat || 'N/A'}</h4>
              </div>
            </div>
            <hr />
            <div className="grid grid-2">
              <div>
                <sub>Cinsiyet</sub>
                <h4>{genders[reservation?.gender] || 'N/A'}</h4>
              </div>
              <div>
                <sub>Yaş</sub>
                <h4>{reservation?.birthday ? dayjs().diff(dayjs(reservation?.birthday), 'years') : 'N/A'}</h4>
              </div>
            </div>
          </div>
          <div className="flex-center">
            <h4>
              <span className={`${reservation?.status === 'success' ? 'success' : 'error'}`}>
                {states[reservation?.status]}
              </span>
            </h4>
          </div>
          <div className="grid" style={{ gap: 'var(--pico-spacing)' }}>
            {reservation?.status === 'success' && (
              <button
                onClick={() => UpdateReservation()}
              >
                Bileti Onayla
              </button>
            )}
            <button
              onClick={Dismiss}
            >
              Vazgeç
            </button>
          </div>
        </article>
      </div>
    </>
  );
};
export default Reservation;
