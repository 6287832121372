/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';

import dayjs from 'dayjs';

import Reservation from './components/Reservation';
import Reader from './components/Reader';
import Info from './components/Info';
import Nav from './components/Nav';

import { get, getToken, setToken } from './client';

import './index.css';

(() => {
  const auth = new URLSearchParams(window.location.search).get('auth');
  return (auth && (auth !== getToken())) ? setToken(auth) : null;
})();

const App = () => {
  const [account, setAccount] = useState(null);
  const [error, setError] = useState(null);
  const [sale, setSale] = useState(null);
  const [qr, setQr] = useState(null);

  const GetAccount = () => {
    get('/accounts/me')
      .then(({ data }) => {
        if (data.status === 'active') {
          setAccount(data);
        } else {
          setError('Geçersiz kullanıcı');
        }
      })
      .catch(() => setError('Geçersiz kullanıcı'));
  };

  const GetSale = () => {
    get(`/sales/${account.sale}`)
      .then(({ data }) => {
        if (dayjs(data.end).isAfter(dayjs())) {
          setSale(data);
        } else {
          setSale({ error: 'Geçersiz satış' });
        }
      })
      .catch(() => setSale({ error: 'Geçersiz satış' }));
  };

  useEffect(() => { GetAccount(); }, []);
  useEffect(() => { if (account && !account.error) GetSale(); }, [account]);

  return (
    account
      ? (
        <>
          <Nav />
          <Reader onQr={setQr} />
          <Info {...{ account, sale, error }} />
          <Reservation {...{ qr }} onDismiss={setQr} onError={setError} />
        </>
      )
      : (
        <main className="flex-center" style={{ height: '100%' }}>
          <h4>{error}</h4>
        </main>
      )
  );
};

const root = ReactDOM.createRoot(document.getElementsByTagName('body')[0]);
root.render(<App />);
